/* FieldMapper.css - Modified to fix overflow and white space */
.page-container {
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(to bottom, #1a1a2e, #16213e);
  padding: 2rem;
  color: #e2e8f0;
  overflow-x: hidden; /* Prevent horizontal overflow */
  max-width: 100vw; /* Ensure it doesn't exceed viewport width */
  box-sizing: border-box; /* Include padding in width calculation */
}

.content-container {
  width: 100%;
  max-width: 1400px; /* Add a max-width to prevent excessive stretching */
  padding: 0 1rem; /* Reduced padding to prevent overflow */
  margin: 0 auto;
  box-sizing: border-box; /* Include padding in width calculation */
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  background: linear-gradient(135deg, #60a5fa, #a78bfa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.header p {
  color: #94a3b8;
  font-size: 1.1rem;
}

.upload-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 3rem;
}

.upload-container {
  background: rgba(30, 41, 59, 0.5);
  border: 1px solid rgba(148, 163, 184, 0.2);
  border-radius: 0.75rem;
  padding: 1.5rem;
  backdrop-filter: blur(8px);
  flex: 1;
  min-width: 300px; /* Minimum width to ensure readability */
  max-width: 100%; /* Prevent overflow */
  box-sizing: border-box;
}

.upload-label {
  display: block;
  margin-bottom: 1rem;
  color: #e2e8f0;
  font-weight: 500;
}

.file-input-wrapper {
  position: relative;
  width: 100%;
}

.file-input-wrapper input[type="file"] {
  display: none;
}

.file-input-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem;
  background: rgba(51, 65, 85, 0.5);
  border: 1px solid rgba(148, 163, 184, 0.3);
  border-radius: 0.5rem;
  color: #e2e8f0;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.file-input-button:hover {
  background: rgba(51, 65, 85, 0.7);
  border-color: rgba(148, 163, 184, 0.5);
}

.fields-section {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 3rem;
}

.fields-section > * {
  flex: 1;
  min-width: 300px; /* Minimum width for readability */
  max-width: 100%; /* Prevent overflow */
  box-sizing: border-box;
}

.fields-container {
  background: rgba(30, 41, 59, 0.5);
  border: 1px solid rgba(148, 163, 184, 0.2);
  border-radius: 0.75rem;
  overflow: hidden;
}

.fields-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(148, 163, 184, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.fields-header h3 {
  margin: 0;
  color: #e2e8f0;
  font-weight: 600;
}

.fields-list {
  padding: 1rem;
}

.field-item {
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  background: rgba(51, 65, 85, 0.3);
  border: 1px solid rgba(148, 163, 184, 0.2);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  word-break: break-word; /* Prevent text from overflowing */
}

.field-item:hover {
  background: rgba(51, 65, 85, 0.5);
  border-color: rgba(148, 163, 184, 0.4);
}

.field-item.selected {
  background: rgba(59, 130, 246, 0.2);
  border-color: #3b82f6;
}

.field-item.mapped {
  background: rgba(34, 197, 94, 0.2);
  border-color: #22c55e;
}

.mappings-section {
  background: rgba(30, 41, 59, 0.5);
  border: 1px solid rgba(148, 163, 184, 0.2);
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin-top: 2rem;
  box-sizing: border-box;
  width: 100%;
}

.mappings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.mapping-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.export-button, .process-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background 0.2s ease;
  white-space: nowrap;
}

.export-button:hover, .process-button:hover {
  background: #2563eb;
}

.mapping-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 1rem;
  padding: 1rem;
  background: rgba(51, 65, 85, 0.3);
  border: 1px solid rgba(148, 163, 184, 0.2);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.remove-mapping {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
  padding: 0.5rem 1rem;
  color: #ef4444;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.remove-mapping:hover {
  color: #dc2626;
  background: rgba(239, 68, 68, 0.1);
  border-radius: 0.25rem;
}

/* Add media queries for better responsiveness */
@media (max-width: 768px) {
  .content-container {
    padding: 0 0.5rem;
  }

  .upload-section, .fields-section {
    flex-direction: column;
  }

  .mappings-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .mapping-actions {
    width: 100%;
  }

  .export-button, .process-button {
    width: 100%;
    justify-content: center;
  }
}

/* Ensure connection lines don't cause overflow */
.connection-lines {
  overflow: visible;
  pointer-events: none;
}